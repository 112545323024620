import React, { Fragment } from "react";
import Link from "next/link";
import Image from "next/image";
import express from "../../../public/img/express.png";
import styles from "@/styles/home.module.scss";
const Expressdelivery = () => {
  return (
    <Fragment>
      <section>
        <div className="container">
          <div className={styles.express}>
            <Link
              href="/jewellery/category/all-products?fastDelivery=true"
              passHref
            >
              <div className="row pointer">
                <div className="col-md-5 col-lg-6">
                  <div className={styles.express_head}>
                    <div className={styles.headline}></div>
                    <h2>Express Delivery</h2>
                    <div className={styles.headline1}></div>
                  </div>
                </div>

                <div className=" col-md-7 col-lg-6">
                  <div className={styles.shipping}>
                    <div className={styles.exp_text}>
                      <p>
                        Buy now and Your Product is ready
                        <br />
                        to be shipped <span>within 72 hours</span>
                      </p>
                    </div>

                    <div className={styles.exp_img}>
                      {/* <Image
                        src="https://sencowebfiles.s3.ap-south-1.amazonaws.com/website/files/XCac8NIJChYTnbtlifHFnGcSIJxZXcJrtJSK2zwx.gif"
                        alt="Express Delivery"
                        width={140}
                        height={65}
                      /> */}
                      <Image
                        className="img-fluid"
                        src={require("@/assets/images/del.gif")}
                        alt="Logo"
                        width={140}
                        height={65}
                        priority
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default Expressdelivery;
